import { useState } from "react";
import Login from "../components/forms/Login";
import { auth } from "../services/yuu/auth";
import { useHistory } from "react-router";
//import { getCsrfToken, signIn } from 'next-auth/client'

import '../styles/login.css'
import mainLogo from'../assets/img/yuu_myshop_logo.png'

export default function LoginPage() {
  const history = useHistory();
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState("");

  const handleSubmitForm = async (e, data) => {
    e.preventDefault();
    try {
      setWaiting(true);
      await auth(data.email, data.password);
      history.push("/pedidos");
      setWaiting(false);
    } catch (error) {
      if (error.response) setError(error.response.data.error);
      else setError("We have a problem!! Tenta denovo daqui a pouco!");
      setWaiting(false);
      setTimeout(() => setError(""), 5000);
    }
  };

  /* const handleSubmitForm = (e,data) => {
    e.preventDefault();
    signIn('credentials', { email : data.email, password: data.password, callbackUrl: `${window.location.origin}/myshop/orders` });
  } */

  return (
    <div className="loginContainer">
      <div className="loginCard">
        <span className="loginError">{error}</span>
        <img
          src={mainLogo}
          width={200}
          style={{ marginBottom: 32 }}
          alt=""
        />
        <Login onSubmit={handleSubmitForm} waiting={waiting} />
      </div>
    </div>
  );
}
