import Input from '../inputs/Input';
import Primary from '../buttons/primary';
import '../../styles/components/forms.css';
import { useState } from 'react';
import { Button } from '@material-ui/core';

export default function Login({waiting, onSubmit}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <form 
      className="login"
      onSubmit={ e => onSubmit(e, {email, password})}
    >
      <Input 
        name="email"  
        placeholder="E-mail"
        type="email"
        onChange={e => setEmail(e.target.value)}
        required
      />
      <Input 
        name="password" 
        placeholder="Senha"
        type="password"
        onChange={e => setPassword(e.target.value)}
        required
      />
      <Primary 
        text="ENTRAR"
        waiting={waiting}
      />
      <div style={{marginTop: 14}}>
        <Button href="password/forgot" className="linkButton" color="primary">Esqueci minha senha</Button>
      </div>
    </form>
  )
}