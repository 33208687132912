import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoginPage from './pages/login';
import OrdersPage from './pages/orders';
import ForgotPassword from './pages/password/forgot';
import ForgotMessage from './pages/password/forgot_message';


const Routes = () => {
  
  return(
  <React.StrictMode>
    <BrowserRouter>
        <Switch>  
          <Route path="/" exact={true} component={LoginPage} />
          <Route path="/orders" exact={true} component={OrdersPage} />
          <Route path="/pedidos" exact={true} component={OrdersPage} />
          <Route path="/password/forgot" exact={true} component={ForgotPassword} />
          <Route path="/password/forgot_message" exact={true} component={ForgotMessage} />
        </Switch>
    </BrowserRouter>
  </React.StrictMode>
  )
};

export default Routes;