import { useState } from 'react';
import Input from '../../components/inputs/Input';
import { forgot } from '../../services/yuu/auth';
import Primary from '../../components/buttons/primary';
import styleForm from '../../styles/components/forms.css';
import { useHistory } from 'react-router';
import '../../styles/login.css';

export default function ForgotPassword() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [error] = useState('');

  const handleSubmitForm = async (e,data) => {
    e.preventDefault();
    setWaiting(true);
    await forgot(email);
    history.push('/password/forgot_message');
    setWaiting(false);
  }

  return (
    <div className="loginContainer">
      <div className="loginCard">
        <span className="loginError">{error}</span>
        <span style={{marginBottom: 21, textAlign: 'center'}}>
          Informe abaixo o e-mail da sua conta para receber o link de redefinição de senha.
        </span>
        <form 
          className={styleForm.login}
          onSubmit={handleSubmitForm}
        >
          <Input 
            name="email"  
            placeholder="E-mail"
            type="email"
            onChange={e => setEmail(e.target.value)}
            required
          />
          <Primary
            text="RECUPERAR MINHA SENHA"
            waiting={waiting}
          />
        </form>
      </div>
    </div>
  )
}
