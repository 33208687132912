import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import CashSound from "../../assets/sounds/new_order.wav";

const Notify = ({index, open, message}) => {
  const [isOpen, setIsOpen] = useState(open);


  return(
      <>
      <audio autoPlay={true} src={CashSound} type="audio/wav"></audio>
      <Snackbar 
        style={{top: index === 0 ? 75 : 75 + 63 * index, cursor:"pointer"}} 
        key={index} 
        open={isOpen} 
        anchorOrigin={{ vertical:'top', horizontal: 'right' }}
        onClick={() => setIsOpen(false)}
      >
        <Alert elevation={4} variant="filled" onClose={() => setIsOpen(false)} severity="warning">
          <span dangerouslySetInnerHTML={{ __html: message}} />
        </Alert>
      </Snackbar>
      </>
  )
} 

export default Notify;