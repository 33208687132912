import {
  Avatar,
  Button,
  Chip,
  Container,
  darken,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  lighten,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import Header from "../components/header/header";
import DataTable from "../components/display/Datatable";
import { format } from "../utils/format/date";
import { money } from "../utils/format/number";
import { all, update } from "../services/yuu/order";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { get, read } from "../services/yuu/notifications";
import { Notify } from "../components/notifications";
import CallMadeRoundedIcon from "@material-ui/icons/CallMadeRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import moment from "moment";
import { getBrand, getPaymentMethod } from "../utils/format/payment";
import { isLogged } from "../services/yuu/auth";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => {
    const getBackgroundColor = (color) =>
    theme.palette.type === "dark"
      ? darken(color, 0.6)
      : lighten(color, 0.6);

  const getHoverBackgroundColor = (color) =>
    theme.palette.type === "dark"
      ? darken(color, 0.5)
      : lighten(color, 0.5);

  return {
    root: {
      "& .super-app-theme--Open": {
        backgroundColor: getBackgroundColor(theme.palette.info.main),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
        },
      },
      "& .super-app-theme--Filled": {
        backgroundColor: getBackgroundColor(theme.palette.success.main),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(theme.palette.success.main),
        },
      },
      "& .super-app-theme--PartiallyFilled": {
        backgroundColor: getBackgroundColor(theme.palette.warning.main),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(theme.palette.warning.main),
        },
      },
      "& .super-app-theme--Rejected": {
        backgroundColor: getBackgroundColor(theme.palette.error.main),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
        },
      },
    },
  };
});

function getClientName(params) {
  const name = params.value;
  return name.firstname + " " + name.lastname;
}

function getLocation(params) {
  const location = params.value;
  if (location)
    return (
      <>
        <a
          style={{ fontWeight: 500, color: "blue" }}
          target="_blank"
          href={`https://www.google.com/maps/place/?q=place_id:${params.value.google_place_id}`}
          rel="noreferrer"
        >
          {location.address_line_1}, {location.address_line_2},{" "}
          {location.address_line_4}, {location.city}
          <CallMadeRoundedIcon
            style={{
              color: "blue",
              fontSize: 16,
              position: "relative",
              bottom: -3,
              left: 3,
            }}
          />
        </a>
      </>
    );
  else return <span>-</span>;
}

function getStatus(params) {
  if (params.row.refused_at) return "Recusado";
  else if (params.row.delivered_at) return "Entregue";
  else if (params.row.delivery_left_at) return "Saiu p/ entrega";
  else if (params.row.accepted_at) return "Aceito";
  else return "Pendente";
}

const getColumns = (colors, onClick, onClickViewItens) => [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "created_at",
    headerName: "Data",
    type: "date",
    width: 150,
    renderCell: (params) => {
      return (
        <Tooltip arrow title={format(params.value, "LLL")}>
          <span>{format(params.value)}</span>
        </Tooltip>
      );
    },
  },
  {
    field: "client",
    type: "string",
    headerName: "Cliente",
    flex: 1,
    valueGetter: getClientName,
  },
  {
    field: "location",
    headerName: "Local",
    flex: 1,
    renderCell: getLocation,
  },
  {
    field: "payment_method",
    width: 200,
    headerName: "Pagamento",
    valueFormatter: (params) => {
      return (
        getPaymentMethod(params.value.method) +
        " " +
        getBrand(params.value.brand)
      );
    },
  },
  {
    field: "amount",
    type: "number",
    width: 150,
    headerName: "Total",
    valueFormatter: (params) => money(params.value),
  },
  {
    field: "itens",
    width: 150,
    headerName: "Itens",
    align: "center",
    renderCell: (params) => (
      <Button
        onClick={() => onClickViewItens(params.row.items)}
        variant="contained"
        color="primary"
        size="small"
        startIcon={<VisibilityRoundedIcon />}
      >
        Ver
      </Button>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    valueGetter: getStatus,
    width: 170,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      getStatus(cellParams1).localeCompare(getStatus(cellParams2)),
    renderCell: (params) => {
      const chips = {
        Pendente: (
          <Chip
            label={params.value}
            style={{
              backgroundColor: `${colors.error.light}33`,
              color: colors.error.dark,
              fontWeight: 600,
            }}
            icon={
              <NavigateNextRoundedIcon style={{ color: colors.error.dark }} />
            }
          />
        ),
        Aceito: (
          <Chip
            label={params.value}
            style={{
              backgroundColor: `${colors.warning.light}33`,
              color: colors.warning.dark,
              fontWeight: 600,
            }}
            icon={
              <NavigateNextRoundedIcon style={{ color: colors.warning.dark }} />
            }
          />
        ),
        "Saiu p/ entrega": (
          <Chip
            label={params.value}
            style={{
              backgroundColor: `${colors.warning.main}33`,
              color: colors.warning.dark,
              fontWeight: 600,
            }}
            icon={
              <NavigateNextRoundedIcon style={{ color: colors.warning.dark }} />
            }
          />
        ),
        Entregue: (
          <Chip
            label={params.value}
            style={{
              backgroundColor: `${colors.success.light}33`,
              color: colors.success.dark,
              fontWeight: 600,
            }}
            icon={<DoneRoundedIcon style={{ color: colors.success.dark }} />}
          />
        ),
        Recusado: <Chip label="Recusado" icon={<CancelRoundedIcon />} />,
      };
      return chips[params.value] || chips["Pendente"];
    },
  },
];

const OrdersPage = () => {
  const classes = useStyles();
  const { palette } = useTheme();
  const [orders, setOrders] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsTotal, setRowsTotal] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editId, setEditID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notifys, setNotifys] = useState([]);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);

  const history = useHistory()

  const columns = getColumns(palette, handleClick, handleOpenItens);

  useEffect(() => {
    if (isLogged()) {
      loadOrders();
      loadNotifications();
      setInterval(() => loadOrders(), 50000);
      setInterval(() => loadNotifications(), 52000);
    } else history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged()) loadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  async function loadOrders() {
    setLoading(true);
    try {
      const result = await all(page);
      if (result.lastPage >= page) {
        setRowsTotal(result.total);
        setOrders(result.data);
      }
    } catch (error) {
      console.warn("error", error);
      alert(
        "Estamos com alguma instabilidade. Verifique a sua internet ou tente novamente daqui a pouco."
      );
    }
    setLoading(false);
  }

  async function loadNotifications() {
    try {
      const result = await get();
      setNotifys(result.data);
      setTimeout(() => readNotifications(), 5000);
    } catch (error) {
      console.warn("error", error);
    }
  }

  async function readNotifications() {
    try {
      const ids = [];
      notifys.forEach((item) => {
        ids.push(item.id);
      });
      if (ids.length > 0) await read(ids);
    } catch (error) {
      console.warn("error", error);
    }
  }

  function handleClick(params, event) {
    setEditID(params.id);
    if (params.field === 'status') setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateStatus = async (status) => {
    try {
      await update(editId, status);
      handleClose();
      loadOrders();
    } catch (error) {
      console.warn("error", error);
      alert("Não foi possível atualizar o Status.");
    }
  };

  function handleOpenItens(items) {
    setItems(items);
    setOpen(true);
  }

  return (
    <>
      <Header />
      <Container maxWidth="xl">
        <Grid>
          <h1>Pedidos</h1>
        </Grid>
        <div className={classes.root}>
          {orders && (
            <DataTable
              rows={orders}
              columns={columns}
              rowCount={rowsTotal}
              pageSize={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              page={page - 1}
              onPageChange={(params) => {
                setPage(params.page + 1);
              }}
              pagination
              loading={loading}
              paginationMode="server"
              autoHeight
              onCellClick={(params, e) => {
                handleClick(params, e);
              }}
              getRowClassName={(params) => {
                const now = moment();
                const created = moment(params.row.created_at);
                const diff = now.diff(created, "minutes");
                if (diff < 60) return "super-app-theme--PartiallyFilled";
                else return "";
              }}
            />
          )}
        </div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => updateStatus("pending")}>Pendente</MenuItem>
          <MenuItem onClick={() => updateStatus("accepted_at")}>
            Em Separação
          </MenuItem>
          <MenuItem onClick={() => updateStatus("delivery_left_at")}>
            Saiu para entrega
          </MenuItem>
          <MenuItem onClick={() => updateStatus("delivered_at")}>
            Entregue
          </MenuItem>
          <MenuItem onClick={() => updateStatus("refused_at")}>
            Cancelar
          </MenuItem>
        </Menu>
        <Dialog
          onClose={() => setOpen(false)}
          open={open}
          maxWidth="md"
          fullWidth
          scroll="paper"
        >
          <DialogTitle>Itens do Pedido</DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>
              <List>
                {items.map((item, index) => {
                  return (
                    <div key={index}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            variant="square"
                            style={{ width: 96, height: 96, marginRight: 14 }}
                            alt={item.product.name}
                            src={item.product.media[0].url}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <div style={{ display: "flex" }}>
                              <Typography
                                variant="h5"
                                style={{ paddingRight: 7 }}
                              >
                                <strong>{item.qty}</strong>
                                <span style={{ fontSize: 16 }}>x</span>{" "}
                              </Typography>
                              <Typography variant="h6">
                                {item.product.name}
                              </Typography>
                            </div>
                          }
                          secondary={
                            <Typography variant="subtitle1">
                              {money(item.price)}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </div>
                  );
                })}
              </List>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setOpen(false)}
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {notifys.map((item, index) => (
          <Notify key={index} open={true} index={index} message={item.message} />
        ))}
      </Container>
    </>
  );
};

export default OrdersPage;
