import Api from "./api";

export async function get(page=1, perPage=10, read=0){
  try {
    const result = await Api.get(`notification`, {params: {page, perPage, read}});
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function read(ids){
  try {
    const result = await Api.put(`notification/read`, {ids});
    return result.data;
  } catch (error) {
    throw error;
  }
}