import '../../styles/login.css';

export default function ForgotMessage() {

  return (
    <div className="loginContainer">
      <div className="loginCard">
        <span style={{marginBottom: 21, textAlign: 'center'}}>
          Foi enviado para o seu e-mail o link para a redefinição de sua senha. Caso não o encontre verifique a sua caixa de SPAM.
        </span>
      </div>
    </div>
  )
}
