import { destroyCookie, parseCookies, setCookie } from "nookies";
import Api from "./api";

const cookies = parseCookies()

export async function auth(email, password){
  
  try {
    const result = await Api.post(`auth`, {email, password});
    window.localStorage.setItem('_tk', result.data.access.access_token);
    setCookie(null, 'yuu_tk', result.data.access.access_token, {
      maxAge: 30 * 24 * 60 * 60,
    })
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function logout(){
  
  try {
    await Api.get(`logout`);
    destroyCookie(null, 'yuu_tk')
    return true
  } catch (error) {
    throw error;
  }
}

export async function me(){
  
  try {
    const result = await Api.get(`me`);
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function forgot(email){
  try {
    const result = await Api.post(`password/forgot`, {email});
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function reset(token, email, password, password_confirmation){
  try {
    const result = await Api.post(`password/reset`, {token, email, password, password_confirmation});
    return result.data;
  } catch (error) {
    throw error;
  }
}

export function isLogged(){
    return cookies.yuu_tk || false
}