import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export default function DataTable(props) {
  return (
      <DataGrid 
        components={{
          Toolbar: GridToolbar,
        }}
        {...props} 
      />
  )
}