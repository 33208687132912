import { CircularProgress } from '@material-ui/core'
import '../../styles/components/buttons.css'

export default function Primary({text, waiting, ...props}) {
  return (
    <button 
      className="primary"
      disabled={waiting}
      {...props}
    >
      {waiting && <CircularProgress color="inherit" size={30} />}
      {!waiting && text}
    </button>
  )
}