import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core'
//import { ptBR } from '@material-ui/core/locale';
import { ptBR } from '@material-ui/data-grid';
import App from './App';

const theme = createMuiTheme({
    palette: {
      primary: {
          light: '#00EAFF',
          main: '#001BA0',
          dark: '#00005B',
          contrastText: '#fff',
      },
      secondary: {
          light: '#FF9400',
          main: '#FF6700',
          dark: '#FF3000',
          contrastText: '#FFF',
      },
  },
}, ptBR);

ReactDOM.render(
    <ThemeProvider theme={theme}>
      <App /> 
    </ThemeProvider>,
  document.getElementById('root')
);
