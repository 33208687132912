import axios from 'axios';
import { parseCookies } from 'nookies';

const apiUrl = process.env.REACT_APP_API_URL;

const Api = axios.create({
    baseURL: apiUrl,
});

Api.interceptors.request.use(
    async config => {
        const cookies = parseCookies()
        let token = cookies.yuu_tk

        if(token){
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
      return Promise.reject(error)
    },
  )



export default Api;