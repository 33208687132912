import Api from "./api";

export async function all(page=1, perPage=10){
  try {
    const result = await Api.get(`order`, {params: {page, perPage}});
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function update(id, status){
  try {
    const result = await Api.put(`order/${id}`, {status});
    return result.data;
  } catch (error) {
    throw error;
  }
}